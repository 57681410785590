import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoIcon from '../assets/logo.png';

const LogoText = styled.h1`
    font-family: 'Akaya Telivigala', cursive;
    font-size: ${(props) => props.theme.fontxxxl};
    color: ${(props) => props.theme.text};
    transition: all 0.2s ease;

    &:hover {
        transform: scale(1.1);
    }

    @media (max-width: 64em) {
        font-size: ${(props) => props.theme.fontxxl};
    }
`;

const Logo = () => {
    return (
        <LogoText>
            <Link
                to="/"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    width={'60px'}
                    style={{ marginRight: '5px', marginLeft: '5px' }}
                    src={logoIcon}
                ></img>
                WSK
            </Link>
        </LogoText>
    );
};

export default Logo;
